import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "detailview-container" }
const _hoisted_2 = { class: "img-and-text" }
const _hoisted_3 = { class: "detail-view-img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "image-overlay"
}
const _hoisted_6 = { class: "image-overlay-content" }
const _hoisted_7 = { class: "text-block" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "upgrade-now-container"
}
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PlayContainer = _resolveComponent("PlayContainer")!
  const _component_PageOverlayLoader = _resolveComponent("PageOverlayLoader")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.medSession)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["detailview-content", {'is-available': _ctx.medSessionFile}])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("figure", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.medSession.image,
                alt: "Meditation Cover"
              }, null, 8, _hoisted_4),
              (!_ctx.checkAvailable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('dialog.no_free_sessions_available')), 1),
                      _createVNode(_component_Button, {
                        text: _ctx.$t('button.upgradeAboNow'),
                        waitForResponse: _ctx.waitForResponse,
                        onHandleClicked: _ctx.handleManageAbo
                      }, null, 8, ["text", "waitForResponse", "onHandleClicked"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.medSession.title), 1),
              _createElementVNode("div", {
                innerHTML: _ctx.medSession.description
              }, null, 8, _hoisted_8)
            ])
          ]),
          (!_ctx.medSessionFile)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                text: _ctx.$t('button.listenNow'),
                class: "listen-now-btn",
                disabled: !_ctx.checkAvailable,
                waitForResponse: _ctx.waitForResponse,
                onHandleClicked: _ctx.handleGetFile
              }, null, 8, ["text", "disabled", "waitForResponse", "onHandleClicked"]))
            : _createCommentVNode("", true),
          (!_ctx.checkAvailable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('info.no_free_sessions_text')), 1),
                _createVNode(_component_Button, {
                  text: _ctx.$t('button.upgradeAboNow'),
                  waitForResponse: _ctx.waitForResponse,
                  onHandleClicked: _ctx.handleManageAbo
                }, null, 8, ["text", "waitForResponse", "onHandleClicked"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.medSessionFile)
            ? (_openBlock(), _createBlock(_component_PlayContainer, {
                key: 2,
                title: _ctx.medSession.title,
                medSessionFile: _ctx.medSessionFile,
                favoritId: _ctx.medSession.favoritId,
                statistic: _ctx.medSession.statistic,
                onHandleToggleFavorite: _ctx.handleToggleFavorite,
                onUpdateStatistic: _ctx.updateStatistic,
                onOnPlay: _ctx.handlePlay,
                onOnPause: _ctx.handlePause
              }, null, 8, ["title", "medSessionFile", "favoritId", "statistic", "onHandleToggleFavorite", "onUpdateStatistic", "onOnPlay", "onOnPause"]))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_PageOverlayLoader)
        ])),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error
    }, null, 8, ["show", "text", "error"])
  ]))
}