
import { defineComponent } from 'vue';
import { PlayContainer, Snackbar, PageOverlayLoader/* , FixedHeaderTop */ } from '@/components';
import { MedSessionsController, UserController, FavoritController, StatisticController, PlayStatisticController } from '@/controller';
import store from '@/store/index';
import { useWakeLock } from '@vueuse/core';
import { Button } from '@/ui';

export default defineComponent({
    name: "MedSessionDetail",
    components: { PlayContainer, Snackbar, PageOverlayLoader, /* FixedHeaderTop, */ Button },
    data(){
        return {
            store,
            medSession: null as any,
            icons:{
                arrow: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" enable-background="new 0 0 500 500" xml:space="preserve"><g><g><line fill="none" stroke="#163D64" stroke-width="25" stroke-miterlimit="184.1689" x1="0" y1="250" x2="500" y2="250"/></g><g><path fill="none" stroke="#163D64" stroke-width="25" stroke-miterlimit="184.1689" d="M175,429.6c0-96.6-78.3-175-175-175"/><path fill="none" stroke="#163D64" stroke-width="25" stroke-miterlimit="184.1689" d="M0,245.4c96.6,0,175-78.3,175-175"/></g></g></svg>',
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>'
            },
            waitForResponse: false,
            snackbar: {
                show: false,
                text: '',
                error: false
            },
            wakeLock: {
                isSupported: false as any,
                isActive: false as any,
                request: null as any,
                release: null as any
            },
            user: {} as any,
            medSessionFile: "",
            sessionNotAvailable: false,
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.getUser()
        if (this.$route.params.id) {
            this.handleGetMedSession(this.$route.params.id)
        }
        this.wakeLock = useWakeLock();
    },
    computed:{
        checkAvailable(){
            if(this.user.freeSessionsLeft > 0 || this.medSessionFile ){
                return true
            } else if(this.sessionNotAvailable) {
                return false
            }
            return false
        }
    },    
    methods: {
        async getUser(){
            this.user = await UserController.user
        },
        handleToggleFavorite(isFavorit:any){
            if(isFavorit){
                this.handleAddFavorit()
            } else {
                this.handleRemoveFavorit()
            }
        },
        handlePlay(currentTime: any) {
            //const { isSupported, isActive, request, release } = useWakeLock();
            if (!this.wakeLock.isSupported && this.wakeLock.isActive) this.wakeLock.request('screen');
            //console.log("Detail:handlePlay:wakeLock", this.wakeLock);
            this.createPlayStatistic(currentTime)
        },
        handlePause() {
            if (this.wakeLock.isSupported && this.wakeLock.isActive) this.wakeLock.release();
            //console.log("Detail:handlePlay:wakeLock", this.wakeLock);
        },
        handleManageAbo(){
            this.$router.push('/manageabo')
        },
        async handleGetMedSession(sessionId:any){
            let res : any = await MedSessionsController.getMedSession(sessionId)
            if(res.data.success){
                this.medSession = {
                    uid: res.data.data.uid,
                    title: res.data.data.title,
                    description: res.data.data.description,
                    duration: res.data.data.duration, 
                    image: res.data.data.image, 
                    favoritId: res.data.data.favoritId,
                    available: res.data.data.available,
                    statistic: {
                        uid: res.data.data.statistic.uid,
                        pausedBy: res.data.data.statistic.pausedBy,
                        startedAt: res.data.data.statistic.startedAt,
                        sessionId: res.data.data.uid
                    }
                }    

                if((this.medSession.available && this.medSession.available == 1) || this.user.abo.allSessionsAvailable == 1){
                    this.handleGetFile()
                } else {
                    this.sessionNotAvailable = true
                }
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },
        async handleGetFile(){
            this.waitForResponse = true
            let res : any = await MedSessionsController.getFile(this.medSession.uid)
            if(res.data.success){
                    //TODO: only for testing -> remove the url part
                const uri = 'https://apprelax.webweb.works/backend'
                this.medSessionFile = res.data.data.file.indexOf('.mp3') != -1 ? uri + res.data.data.file : res.data.data.file
                this.medSession.statistic = res.data.data.statistic
                this.sessionNotAvailable = false
            } else {
                if(res.data.errors[0].field == 'abo'){
                    this.sessionNotAvailable = true
                } else {
                    this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
                }
            }
            this.user.freeSessionsLeft = res.data.data.freeSessionsLeft
            this.waitForResponse = false
        },
        /*------------------ Favorit ------------------*/
        async handleAddFavorit(){
            let res = await FavoritController.addFavorit(this.medSession.uid )
            if(res.data.success){
                this.medSession.favoritId = res.data.data.favoritId 
                
                MedSessionsController.medSessions.forEach((item:any) =>{
                    if(item.uid == this.medSession.uid){
                        UserController.user.favorits.push(item)
                    }
                });
                this.handleShowSnackbar(this.$t('snackbar.favoritAdded'), false)

            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },
        async handleRemoveFavorit(){
            let res = await FavoritController.removeFavorit(this.medSession.favoritId)
            if(res.data.success){
                this.medSession.favoritId = 0
                 UserController.user.favorits = UserController.user.favorits.filter((item:any) =>{
                    return item.uid != this.medSession.uid 
                })
                this.handleShowSnackbar(this.$t('snackbar.favoritRemoved'), false)

            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },
         /*------------------ Statistic ------------------*/
        async updateStatistic(updateData:any){
            let res = await StatisticController.updateStatistic(updateData, this.medSession.uid)
            if(res.data.success){
                this.medSession.statistic = res.data.data
            }
        },    
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
           }, 5000)
        },
        async createPlayStatistic(currentTime: any) {
            if (currentTime == 0) {
                await PlayStatisticController.create(this.medSession.uid)
            }
        }
    }
})
